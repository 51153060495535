<template>
  <NotificationContainer :readedAt="readedAt" error>
    <div class="notification-detail">
      <p>
        <b
          >Impossible d'envoyer l'attachement
          <router-link :to="'/sst/attachement/' + context.attachementId">{{
            context.attachementReference
          }}</router-link>
          dans Oracle</b
        >
      </p>
      <p class="py-2">
        {{ context.errorMessage }}
        <ul v-if="context.errors">
          <li v-for="errorMessage in context.errors" :key="errorMessage">
            {{ errorMessage }}
          </li>
        </ul>
      </p>
      <span class="text-muted">
        <i>
          <small>{{ createdAt | fromNow }}</small>
        </i>
      </span>
    </div>
  </NotificationContainer>
</template>

<script>
export default {
  name: "OracleSendReceiptFailure",
  props: {
    readedAt: {},
    context: {},
    creator: {},
    template: {},
    createdAt: {},
    user: {},
  },
};
</script>

<style lang="scss" scoped>
.notification-detail {
  p {
    margin-bottom: 5px;
    line-height: 1.2;
  }
}

.py-2 {
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
