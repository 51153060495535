var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_vm._v(" s "),_c('div',{staticClass:"container is-fluid"},[_c('page-header',{attrs:{"entity":_vm.$entitiesName.Project,"create":_vm.openCreateModal}},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("common.projects")))])]),_c('datatable',{ref:"listProject",attrs:{"fetch":_vm.$api.fetchProjects,"enableDownload":true,"objectName":"projects"},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.name'),"sortable":true}},[_vm._v(" "+_vm._s(item.name)+" ")])}},{key:"code",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.code'),"sortable":true}},[_vm._v(" "+_vm._s(item.code)+" ")])}},{key:"type",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.type'),"sortable":true}},[(item.type)?_c('span',[_vm._v(_vm._s(item.type.name))]):_vm._e()])}},{key:"manager",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.manager'),"sortable":true}},[_vm._v(" "+_vm._s(item.manager)+" ")])}},{key:"actions",fn:function(ref){
var item = ref.item;
return _c('div',{},[_c('router-link',{staticClass:"button is-primary",attrs:{"to":'/project/' + item.id}},[_c('i',{staticClass:"fa fa-search"})])],1)}}])})],1),_c('modal',{ref:"createProjectModal",attrs:{"title":_vm.$t('projects.projectCreation')},on:{"create":_vm.createProject}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half"},[_c('text-field',{attrs:{"label":_vm.$t('common.name'),"inline":false,"edit":true,"required":""},model:{value:(_vm.project.name),callback:function ($$v) {_vm.$set(_vm.project, "name", $$v)},expression:"project.name"}}),_c('text-field',{attrs:{"label":_vm.$t('common.code'),"inline":false,"edit":true,"required":"","validate":"min:3,max:100"},model:{value:(_vm.project.code),callback:function ($$v) {_vm.$set(_vm.project, "code", $$v)},expression:"project.code"}}),_c('selector-field',{attrs:{"label":_vm.$t('common.type'),"options":_vm.$api.fetchProjectTypesAsList,"inline":false,"edit":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(option.name))]}}]),model:{value:(_vm.project.type),callback:function ($$v) {_vm.$set(_vm.project, "type", $$v)},expression:"project.type"}}),_c('text-field',{attrs:{"label":_vm.$t('common.manager'),"inline":false,"edit":true},model:{value:(_vm.project.manager),callback:function ($$v) {_vm.$set(_vm.project, "manager", $$v)},expression:"project.manager"}}),_c('textarea-field',{attrs:{"label":_vm.$t('projects.directorAddress'),"inline":false,"edit":true},model:{value:(_vm.project.address),callback:function ($$v) {_vm.$set(_vm.project, "address", $$v)},expression:"project.address"}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }