import OracleSendOrderFailure from "@/components/notifications/OracleSendOrderFailure.vue";
import OracleSendOrderSuccess from "@/components/notifications/OracleSendOrderSuccess.vue";
import OracleSendReceiptFailure from "@/components/notifications/OracleSendReceiptFailure.vue";
import OracleSendReceiptSuccess from "@/components/notifications/OracleSendReceiptSuccess.vue";
import PopupNotification from "@/components/notifications/PopupNotification.vue";
import TestNotification from "@/components/notifications/TestNotification.vue";

export default {
  test_notification: TestNotification,
  popup_notification: PopupNotification,
  oracleSendOrderFailure: OracleSendOrderFailure,
  oracleSendOrderSuccess: OracleSendOrderSuccess,
  oracleSendReceiptFailure: OracleSendReceiptFailure,
  oracleSendReceiptSuccess: OracleSendReceiptSuccess,
};
