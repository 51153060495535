<template>
  <NotificationContainer :readedAt="readedAt">
    <div class="notification-detail">
      <div>No template for this notification type {{ template }}!</div>
      <div v-if="context">Context available: {{ context }}</div>
      <span class="text-muted"
        ><small>{{ createdAt | fromNow }}</small></span
      >
    </div>
  </NotificationContainer>
</template>

<script>
export default {
  name: "EmptyNotification",
  props: {
    template: {},
    context: {},
    createdAt: {},
    readedAt: String,
  },
};
</script>

<style lang="scss" scoped>
.notification-detail {
  p {
    margin-bottom: 5px;
    line-height: 1.2;
  }
}
</style>
