<template>
  <div
    class="notification"
    :class="{
      'notification--unread': readedAt === null,
      'notification--success': success,
      'notification--error': error,
      'notification--warning': warning,
    }"
  >
    <div class="notification-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "NotificationContainer",
  props: {
    success: Boolean,
    error: Boolean,
    warning: Boolean,
    readedAt: String,
  },
};
</script>

<style lang="scss" scoped>
$blue: #29b6f6;
$green: #4caf50;
$red: #f44336;
$yellow: #ff9800;

.notification {
  color: black;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  margin-bottom: 7px;
  margin-right: 10px;
  margin-left: 10px;
  background: #fff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.06);
  border-left: 5px solid #eee;
  &--success {
    border-left: 5px solid $green;
    background-color: #f0fdf4;
  }
  &--error {
    border-left: 5px solid $red;
    background-color: #fef3f2;
  }
  &--warning {
    border-left: 5px solid $yellow;
    background-color: #fffbeb;
  }
  &--unread {
    border-left: 5px solid $blue;
    transition: none;
  }
  transition: all 0.5s ease-out 1s;

  .notification-content {
    display: flex;
    width: 100%;
  }
}
</style>
