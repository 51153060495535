<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.Order" :create="openCreateModal">
        <h1 class="title">{{ $t("common.order") }}</h1>
      </page-header>

      <div v-if="hasMyActions" class="level">
        <div class="field has-addons">
          <p class="control">
            <a
              cy-data="view_all"
              class="button"
              :class="{ 'is-primary': toDoFilter === 'all' }"
              @click="setToDoFilter('all')"
              >{{ $t("common.all") }}</a
            >
          </p>

          <p class="control">
            <a
              cy-data="my_scope"
              class="button"
              :class="{ 'is-primary': toDoFilter === 'canDo' }"
              @click="setToDoFilter('canDo')"
              >{{ $t("expressions.myScope") }}</a
            >
          </p>

          <p class="control">
            <a
              cy-data="my_tasks"
              class="button"
              :class="{ 'is-primary': toDoFilter === 'haveToDo' }"
              @click="setToDoFilter('haveToDo')"
              >{{ $t("expressions.myTasks") }}</a
            >
          </p>
        </div>
      </div>

      <datatable
        :fetch="$api.fetchOrders"
        ref="orderList"
        :enableDownload="true"
        objectName="orders"
        order="DESC"
        :externalFilters="{ filter: filter }"
      >
        <div
          slot="reference"
          slot-scope="{ item }"
          :title="$t('common.reference')"
          :sortable="true"
        >
          {{ item.reference }}
        </div>
        <div
          slot="name"
          slot-scope="{ item }"
          :title="$t('orders.columns.complement')"
          :sortable="true"
        >
          {{ item.name }}
        </div>
        <div
          slot="market"
          slot-scope="{ item: { market } }"
          :title="$t('common.contractorAgreement')"
          class="nowrap"
          :sortable="true"
          sortBy="market.edifice"
        >
          <restricted-link
            v-if="market"
            entity="subcontractor_market"
            :to="`/sst/market/${market.id}`"
          >
            {{ market.edifice }}
          </restricted-link>
        </div>
        <div
          slot="market.project"
          slot-scope="{ item: { market } }"
          :title="$t('common.project')"
          :sortable="true"
          sortBy="market.project.name"
        >
          <span v-if="market && market.project">{{ market.project.name }}</span>
        </div>
        <div
          slot="contractNumber"
          slot-scope="{ item }"
          :title="$t('orders.columns.contractNumber')"
          :sortable="true"
        >
          {{ item.market && item.market.contractNumber }}
        </div>
        <div
          slot="client_market"
          slot-scope="{ item }"
          :title="$t('orders.columns.clientMarket')"
        >
          {{ item.clientMarket }}
        </div>
        <div
          slot="client_bdc"
          slot-scope="{ item }"
          :title="$t('orders.columns.bdcCustomer')"
        >
          {{ item.clientBdc }}
        </div>
        <div
          slot="end_date"
          slot-scope="{ item }"
          :title="$t('expressions.endDate')"
        >
          {{ item.endDate }}
        </div>
        <div
          slot="oscarSubProject"
          slot-scope="{ item: { oscarSubProject } }"
          :title="$t('oscarSubProjects.columns.oscarSubProject')"
          v-if="ActiveProfile.isFTTH"
        >
          <span v-if="oscarSubProject">{{ oscarSubProject.name }}</span>
        </div>
        <div
          slot="arcgis"
          slot-scope="{ item: { oscarSubProject } }"
          :title="$t('oscarSubProjects.columns.arcgisName')"
          v-if="ActiveProfile.isFTTH"
        >
          <span v-if="oscarSubProject">{{ oscarSubProject.arcgisName }}</span>
        </div>
        <div
          slot="nro"
          slot-scope="{ item: { oscarSubProject } }"
          :title="$t('common.nro')"
          v-if="ActiveProfile.isFTTH"
        >
          <span v-if="oscarSubProject">{{ oscarSubProject.nroName }}</span>
        </div>
        <div
          slot="pm"
          slot-scope="{ item: { oscarSubProject } }"
          :title="$t('common.pm')"
          v-if="ActiveProfile.isFTTH"
        >
          {{ oscarSubProject && oscarSubProject.offerIdPm }}
        </div>
        <div
          slot="imputation"
          slot-scope="{ item: { imputation } }"
          :title="$t('common.imputation')"
          :sortable="true"
          sortBy="imputation.eotp"
        >
          {{ imputation && imputation.eotp }}
        </div>
        <div
          slot="follower"
          slot-scope="{ item: { follower } }"
          :title="$t('orders.columns.managedBy')"
          :sortable="true"
          sortBy="follower.name"
        >
          {{ follower && follower.name }}
        </div>
        <div
          slot="total"
          slot-scope="{ item }"
          :title="$t('common.total')"
          class="nowrap has-text-right"
          :sortable="true"
          sortBy="amount"
        >
          {{ item.total | priceEUR }}
        </div>

        <div
          slot="alreadyInvoiced"
          slot-scope="{ item }"
          :title="$t('orders.invoiced')"
          nofilter
          class="nowrap has-text-right"
        >
          {{ item.alreadyInvoiced | priceEUR }}
        </div>

        <div
          slot="statusWF"
          slot-scope="{ item }"
          :title="$t('common.status')"
          class="nowrap"
        >
          <span v-if="item.statusWF">
            <router-link
              :to="'/node/' + item.nodeId"
              :title="item.statusWF.createdAt | date"
              >{{ item.nodeName }}</router-link
            >
            ({{ item.statusWF.createdAt | date }})
          </span>
        </div>
        <div
          slot="creator"
          slot-scope="{ item }"
          :title="$t('common.creator')"
          :sortable="true"
          sortBy="creator.name"
        >
          {{ item.creator }}
        </div>

        <div slot="actions" slot-scope="{ item }">
          <router-link :to="'/sst/order/' + item.id" class="button is-primary">
            <i class="fa fa-search"></i>
          </router-link>
        </div>
      </datatable>
    </div>

    <modal
      ref="createOrderModal"
      @create="createOrder"
      :title="$t('orders.orderCreation')"
    >
      <div class="columns">
        <div class="column is-half">
          <text-field
            :label="$t('orders.columns.complement')"
            data-test="sel-os-details"
            v-model="order.name"
            :inline="false"
            :edit="true"
            required
            :validate="{ max: maxNameLength }"
            cy-data="complement-details"
          ></text-field>
          <text-field
            :label="$t('orders.columns.mainClient')"
            v-model="order.mainClient"
            :inline="false"
            :edit="true"
          ></text-field>
          <text-field
            :label="$t('orders.columns.clientMarket')"
            v-model="order.clientMarket"
            :inline="false"
            :edit="true"
            data-test="sel-os-client-market"
            cy-data="client-market"
          ></text-field>
          <text-field
            :label="$t('orders.columns.bdcCustomer')"
            v-model="order.clientBdc"
            :inline="false"
            :edit="true"
            data-test="sel-os-client-bdc"
            cy-data="os-client-bdc"
          ></text-field>
          <date-field
            :label="$t('expressions.endDate')"
            v-model="order.endDate"
            :inline="false"
            :edit="true"
            data-test="sel-os-end-date"
            cy-data="end-date"
            required
          ></date-field>
          <text-field
            :label="$t('orders.columns.deliveryDuration')"
            v-model="order.executionPeriod"
            :inline="false"
            :edit="true"
          ></text-field>
          <textarea-field
            :label="$t('common.observation')"
            v-model="order.observation"
            :inline="false"
            :edit="true"
            data-test="sel-os-observation"
            cy-data="observation"
          ></textarea-field>
        </div>

        <div class="column is-half">
          <many2one-field
            :label="$t('entities.contractorAgreement')"
            v-model="order.market"
            :fetch="$api.fetchActiveMarkets"
            reference="edifice"
            @click="(item) => $router.push('/sst/market/' + item.id)"
            :columns="marketColumns"
            :inline="false"
            :edit="true"
            required
            :hasFilter="true"
            v-validate="{ required: true }"
            name="markets2one"
            :error="errors.has('markets2one')"
            data-test="sel-os-market"
          ></many2one-field>
          <article
            v-if="
              order.market &&
              order.market.agency &&
              order.market.agency.synchronize &&
              !(
                order.market.agency.documentsCompleted &&
                order.market.agency.status === 1
              )
            "
            class="message is-danger"
          >
            <div class="message-body">{{ $t("agencies.anomalies") }}</div>
          </article>
          <many2one-field
            :label="$t('entities.orderTemplate')"
            v-model="order.orderTemplate"
            :fetch="fetchFilteredOrderTemplate"
            reference="name"
            :columns="orderTemplateColumns"
            :inline="false"
            :hasFilter="true"
            :edit="true"
            :readonly="order.market == null"
            name="orderTemplates2one"
            :error="errors.has('orderTemplates2one')"
            data-test="sel-os-template"
            order="DESC"
          >
            <span slot="helper" v-if="order.orderTemplate == null">{{
              $t("orders.needMarket")
            }}</span>
          </many2one-field>
          <many2one-field
            :label="$t('common.imputation')"
            v-model="order.imputation"
            :fetch="fetchFilteredImputation"
            reference="eotp"
            @click="(item) => $router.push('/sst/company/' + item.id)"
            :columns="imputationColumns"
            :inline="false"
            :hasFilter="true"
            :edit="true"
            required
            :readonly="order.market == null"
            v-validate="{ required: true }"
            name="imputations2one"
            :error="errors.has('imputations2one')"
            data-test="sel-os-imputation"
          >
            <span slot="helper" v-if="order.market == null">{{
              $t("orders.needMarket")
            }}</span>
          </many2one-field>

          <!-- Set the Oracle project associated with the market -->
          <many2one-field
            :label="$t('entities.oracleProject')"
            v-model="order.oracleProject"
            :fetch="$api.fetchOracleProjectsByProject.bind(this, projectId)"
            reference="name"
            entity="oracle_project"
            :columns="oracleProjectColumns"
            :edit="true"
            :hasFilter="true"
            :inline="false"
            :readonly="order.market == null"
          >
            <span slot="helper" v-if="order.market == null">{{
              $t("orders.needMarket")
            }}</span>
          </many2one-field>

          <!-- Set the Oracle task associated with the Oracle project-->
          <many2one-field
            :label="$t('entities.oracleTask')"
            v-model="order.oracleTask"
            :fetch="
              $api.fetchOracleTasksByOracleProject.bind(
                this,
                order.oracleProject && order.oracleProject.projectId
              )
            "
            reference="name"
            entity="oracle_task"
            :columns="oracleTaskColumns"
            :edit="true"
            :hasFilter="false"
            :inline="false"
            :readonly="order.market == null || order.oracleProject == null"
          >
            <span
              slot="helper"
              v-if="order.market == null || order.oracleProject == null"
            >
              {{
                order.market == null
                  ? $t("orders.needMarket")
                  : $t("orders.needOracleProject")
              }}</span
            >
          </many2one-field>

          <!-- Set the OSCAR subproject -->
          <many2one-field
            :label="$t('entities.oscarSubProject')"
            v-model="order.oscarSubProject"
            :fetch="$api.fetchOscarSubProjectsByProject.bind(this, projectId)"
            reference="name"
            entity="oscar_project"
            :columns="oscarSubProjectColumns"
            :edit="true"
            :hasFilter="true"
            :inline="false"
            :readonly="order.market == null"
            data-test="sel-project-oscar-subproject"
          >
            <span slot="helper" v-if="order.market == null">{{
              $t("orders.needMarket")
            }}</span>
          </many2one-field>
          <many2one-field
            :label="$t('orders.columns.orderManagedBy')"
            v-model="order.follower"
            :fetch="$api.fetchUsers"
            reference="name"
            @click="(item) => $router.push('/user/' + item.id)"
            :columns="userColumns"
            :hasFilter="true"
            :inline="false"
            :edit="true"
            required
            name="follower"
            v-validate="{ required: true }"
            :error="errors.has('follower')"
            data-test="sel-os-follower"
          ></many2one-field>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Datatable from "../../components/Datatable";
import AgencyDisplay from "../../components/displays/AgencyDisplay.vue";
import { globalLocale } from "../../i18n";

export default {
  name: "service-order",
  components: {
    Datatable,
  },
  data() {
    return {
      order: {},
      marketColumns: {
        "project.name": this.$t("common.project"),
        "agency.company.name": this.$t("common.company"),
        edifice: this.$t("orders.columns.edifice"),
        agency: {
          title: this.$t("agencies.columns.edifice"),
          component: AgencyDisplay,
          filter: "",
        },
        maxAmountDC4: this.$t("markets.columns.maxAmountDC4"),
        eotpST: this.$t("markets.columns.eotpSt"),
        "bpu.name": this.$t("entities.bpu"),
        tva: this.$t("common.vat"),
        depositType: this.$t("common.retention"),
        paymentType: this.$t("markets.columns.paymentType"),
      },
      orderTemplateColumns: {
        name: this.$t("expressions.templateName"),
      },
      imputationColumns: {
        eotp: this.$t("common.eotp"),
        description: this.$t("common.description"),
      },
      oracleProjectColumns: {
        name: this.$t("common.name"),
        description: this.$t("common.description"),
      },
      oracleTaskColumns: {
        name: this.$t("common.name"),
        number: this.$t("common.number"),
        description: this.$t("common.description"),
      },
      oscarSubProjectColumns: {
        "oscarProject.name": this.$t("entities.oscarProject"),
        name: this.$t("entities.oscarSubProject"),
        arcgisName: this.$t("oscarSubProjects.columns.arcgisReference"),
      },
      userColumns: {
        name: this.$t("common.name"),
        mail: "E-Mail",
        "role.name": this.$t("entities.role"),
        service: this.$t("common.service"),
      },
      toDoFilter: "haveToDo",
      globalLocale: globalLocale,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      hasMyActions: "auth/hasMyActions",
      ActiveProfile: "auth/getActiveProfile",
    }),
    filter() {
      if (!this.hasMyActions) {
        return "all";
      }
      return this.toDoFilter;
    },
    maxNameLength() {
      const market = this.order.market
        ? (this.order.market.edifice.length >= 60
            ? 60
            : this.order.market.edifice.length) + 1
        : 0;
      return 100 - (market + 12);
    },
    projectId() {
      return this.order.market && this.order.market.project
        ? this.order.market.project.id
        : 0;
    },
  },
  watch: {
    // eslint-disable-next-line
    "order.market": function (v1, v2) {
      if (!v1 || (v2 && v1.id !== v2.id)) {
        this.order.imputation = null;
        this.order.oracleProject = null;
        this.order.oracleTask = null;
        this.order.oscarSubProject = null;
      }
    },
  },
  methods: {
    openCreateModal() {
      this.$refs.createOrderModal.open();
    },
    fetchFilteredOrderTemplate(p) {
      const params = p;
      if (!params.filters) {
        params.filters = {};
      }
      params.filters.bpuTemplateId = this.order.market.bpuTemplate.id || 0;
      return this.$api.fetchOsTemplates(params);
    },
    fetchFilteredImputation(p) {
      const params = p;
      if (!params.filters) {
        params.filters = {};
      }
      params.filters.projectId = this.order.market.project.id;
      params.filters.active = true;
      return this.$api.fetchImputations(params);
    },
    createOrder() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
        const self = this;
        const order = this.order;
        // FIXME: Should use an endpoint that filters or removes markets that don't have BPU
        if (!order.market.bpu) {
          // eslint-disable-next-line
          alert("Le marché choisi n'a aucun BPU associé");
          return;
        }
        order.bpu = order.market.bpu;
        delete order.bpu["@id"];
        axios.post("/order/create", order).then((response) => {
          if (!response.data.success) {
            return;
          }

          // Reset the create order form
          self.order = {};

          // Refresh the list of orders
          // NOTE: Unnecessary as we redirect the user to the order edition page.
          //self.$refs.orderList.refresh();

          // Close the modal
          //self.$refs.createOrderModal.close();

          // Go to the service order view.
          self.$router.push({
            name: "ServiceOrder",
            params: { id: response.data.id },
          });
        });
      });
    },
    setToDoFilter(action) {
      this.toDoFilter = action;
    },
  },
};
</script>

<style scoped>
.level {
  display: flex;
  justify-content: flex-end;
}
</style>
