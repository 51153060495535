<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header
        :entity="$entitiesName.Attachement"
        :edit="!isLoading && !attachement.locked ? edit : null"
        :save="save"
        :cancel="cancel"
        :remove="!isLoading && !attachement.locked ? remove : null"
      >
        <h1 class="title" cy-data="cy-attachement-title">
          <breadcrumb
            location="/sst/attachements"
            :text="$t('entities.attachment')"
          ></breadcrumb>
          {{ attachement.reference }}
        </h1>

        <template slot="actions" v-if="!isLoading">
          <button
            v-if="attachement.canSendToOracle"
            class="button is-warning"
            @click="sendToOracle"
            :class="{ 'is-loading': sendToOracleLoader }"
            :disabled="sendToOracleLoader"
          >
            {{ $t("attachments.resendToOracle") }}
          </button>
          <button
            v-if="
              !editMode &&
              currentTab === 1 &&
              !attachement.locked &&
              getRights($entitiesName.Attachement).update
            "
            class="button is-primary"
            @click="importClick"
          >
            {{ $t("common.import") }}
          </button>
          <form
            v-if="!editMode && currentTab === 1"
            class="inline-form"
            method="POST"
            target="_blank"
            :action="`${API}/attachement/${id}/export?sort=${sorting}`"
          >
            <button type="submit" class="button is-primary">
              {{ $t("common.exporter") }}
            </button>
            <input type="hidden" name="token" :value="authToken" />
          </form>
          <form
            v-if="!editMode && currentTab === 1"
            class="inline-form"
            method="POST"
            target="_blank"
            :action="`${API}/attachement/${id}/export/bpu?sort=${sorting}`"
          >
            <button type="submit" class="button is-primary">
              {{ $t("attachments.exportBPU") }}
            </button>
            <input type="hidden" name="token" :value="authToken" />
          </form>

          <form
            v-if="!editMode"
            class="inline-form"
            method="POST"
            target="_blank"
            :action="`${API}/attachement/${id}/pdf`"
          >
            <button type="submit" class="button is-success downloadButton">
              {{ $t("common.download") }}
            </button>
            <input type="hidden" name="token" :value="authToken" />
          </form>
        </template>
      </page-header>

      <div class="panel">
        <div class="panel-heading has-background-primary">
          <div class="tabs">
            <ul>
              <li
                :class="{ 'is-active': currentTab === 0 }"
                @click="currentTab = 0"
                cy-data="info-tab"
              >
                <a>{{ $t("common.information") }}</a>
              </li>
              <li
                :class="{ 'is-active': currentTab === 1 }"
                @click="currentTab = 1"
                cy-data="prestation-tab"
              >
                <a>{{ $t("common.prestations") }}</a>
              </li>
              <li
                :class="{ 'is-active': currentTab === 2 }"
                @click="currentTab = 2"
                cy-data="document-tab"
              >
                <a>{{ $t("common.documents") }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="panel-block is-block no-border" v-if="currentTab === 0">
          <div class="columns">
            <div class="column is-half" v-if="attachement">
              <field
                cy-data="reference"
                :label="$t('common.reference')"
                v-model="attachement.reference"
                readonly
              ></field>
              <field :label="$t('orders.columns.contractNumber')" readonly>
                <span
                  cy-data="field-subcontractor-contract-number"
                  v-if="attachement.market"
                  >{{ attachement.market.contractNumber }}</span
                >
              </field>
              <field :label="$t('attachments.amountWoVat')" readonly>
                <span cy-data="cy-wt-amount" v-if="attachement.amount">{{
                  attachement.amount | priceEUR
                }}</span>
              </field>

              <checkbox-field
                cy-data="dgd"
                :label="
                  attachement.isDGD && validated
                    ? $t('attachments.finalBilling')
                    : $t('common.dgd')
                "
                v-model="attachement.isDGD"
              ></checkbox-field>

              <textarea-field
                cy-data="observation"
                :label="$t('common.observation')"
                v-model="attachement.observation"
              ></textarea-field>
              <field cy-data="wf" :label="$t('attachments.wfStatus')" readonly>
                <template v-if="attachement.statusWF">
                  <router-link
                    cy-data="field-workflow-status-display"
                    :to="'/node/' + attachement.statusWF.node.id"
                    :title="attachement.statusWF.createdAt | datetime"
                    >{{ attachement.statusWF.node.name }}</router-link
                  >
                  ({{ attachement.statusWF.createdAt | fromNow }})
                </template>
              </field>
              <field
                :label="$t('attachments.cumulativePreviousAmount')"
                readonly
              >
                <template v-if="loadingPrestations && !cumulativePreviousCost">
                  <i class="loader"></i>
                </template>
                <template v-else>
                  <span cy-data="field-cumulative-previous-cost-display">{{
                    cumulativePreviousCost | priceEUR
                  }}</span>
                </template>
              </field>
              <field :label="$t('attachments.cumulativeAmount')" readonly>
                <template v-if="loadingPrestations && !cumulativeCost">
                  <i class="loader"></i>
                </template>
                <template v-else>
                  <span cy-data="field-cumulative-cost-display">{{
                    cumulativeCost | priceEUR
                  }}</span>
                </template>
              </field>
              <field :label="$t('attachments.currentPeriodAmount')" readonly>
                <template v-if="loadingPrestations && !totalPrestationCost">
                  <i class="loader"></i>
                </template>
                <template v-else>
                  <span cy-data="field-total-prestation-cost-display">{{
                    totalPrestationCost | priceEUR
                  }}</span>
                </template>
              </field>
              <field :label="$t('common.order')" readonly>
                <restricted-link
                  container="span"
                  v-if="attachement.order"
                  entity="market_sst"
                  :to="'/sst/order/' + attachement.order.id"
                >
                  <span cy-data="field-service-order-display">{{
                    attachement.order.reference
                  }}</span>
                </restricted-link>
              </field>
              <field :label="$t('common.contractorAgreement')" readonly>
                <restricted-link
                  container="span"
                  v-if="attachement.market"
                  entity="market_sst"
                  :to="'/sst/market/' + attachement.market.id"
                >
                  <span cy-data="field-subcontractor-display">{{
                    attachement.market.edifice
                  }}</span>
                </restricted-link>
              </field>
              <field :label="$t('common.agency')" readonly>
                <restricted-link
                  container="span"
                  v-if="attachement.agency"
                  entity="agency"
                  :to="'/sst/agency/' + attachement.agency.id"
                >
                  <agency-display :value="attachement.agency" />
                </restricted-link>
              </field>
              <field
                :label="$t('oscarSubProjects.columns.arcgisName')"
                v-if="ActiveProfile.isFTTH"
                readonly
              >
                <template v-if="attachement.oscarSubProject">
                  <span cy-data="field-arcgis-name-display">{{
                    attachement.oscarSubProject.arcgisName
                  }}</span>
                </template>
              </field>
              <field
                :label="$t('common.nro')"
                v-if="ActiveProfile.isFTTH"
                readonly
              >
                <template v-if="attachement.oscarSubProject">
                  <span cy-data="field-nro-display">{{
                    attachement.oscarSubProject.nroName
                  }}</span>
                </template>
              </field>
              <field
                :label="$t('common.pm')"
                v-if="ActiveProfile.isFTTH"
                readonly
              >
                <template v-if="attachement.oscarSubProject">
                  <span cy-data="field-pm-display">{{
                    attachement.oscarSubProject.offerIdPm
                  }}</span>
                </template>
              </field>
              <field :label="$t('common.eotp')" readonly>
                <template v-if="attachement.imputation">
                  <span cy-data="field-eotp-display">{{
                    attachement.imputation.eotp
                  }}</span>
                </template>
              </field>
              <field
                :label="$t('attachments.completionDate')"
                readonly
                v-if="attachement.order"
              >
                <span cy-data="field-end-date-display">{{
                  attachement.order.endDate
                }}</span>
              </field>
              <field :label="$t('attachments.totalAmount')" readonly>
                <template v-if="loadingPrestations && !osTotalCost">
                  <i class="loader"></i>
                </template>
                <template v-else>
                  <span cy-data="field-total-amount-display">{{
                    osTotalCost | priceEUR
                  }}</span>
                </template>
              </field>
              <field :label="$t('orders.columns.orderManagedBy')" readonly>
                <template v-if="attachement.follower">
                  <span cy-data="field-follower-display">{{
                    attachement.follower.name
                  }}</span>
                </template>
              </field>
              <field :label="$t('attachments.observationCMD')" readonly>
                <template v-if="attachement.order">
                  <span cy-data="field-observation-os-display">{{
                    attachement.order.observation
                  }}</span>
                </template>
              </field>
            </div>
            <div class="column is-half">
              <workflow-history
                v-model="workflowComment"
                :entity="{
                  ...attachement,
                  totalOS: osTotalCost,
                  totalAttachements: cumulativeCost,
                }"
                :entityName="WorkflowCodes.ATT.NODE.OBJECT_NAME"
                :entityId="id"
                @updated="fetchData"
              ></workflow-history>
            </div>
          </div>
        </div>
        <div class="panel-block is-block no-border" v-if="currentTab === 1">
          <flexi-table
            class="is-fullwidth"
            :loader="returnPrestations"
            :columns="prestationTableColumns"
            :showIndex="true"
            @input="updatePrestationsModel"
            ref="prestationsTable"
            :hasActions="editMode"
            :key="'prest_' + prestations.length"
            @sortBy="updateSort"
          >
            <template v-if="editMode" slot="extraHeaderRow">
              <td class="index"></td>
              <td v-for="(c, i) in prestationTableColumns" :key="i">
                <template
                  v-if="
                    !c.name.includes('amount') && !c.name.includes('quantity')
                  "
                >
                  <input v-model="c.filter" class="input" type="text" />
                </template>
              </td>
              <td></td>
            </template>
            <template slot="firstDataRow">
              <tr
                v-if="
                  !editMode && !osContainsPrestations && !loadingPrestations
                "
              >
                <td colspan="16" :style="{ textAlign: 'center' }">
                  {{ $t("attachments.unrecordedBenefits") }}
                </td>
              </tr>
            </template>
            <template slot="dataRows" slot-scope="{ item, index }">
              <tr class="table-data" v-if="!editMode && osContainsPrestations">
                <!-- index -->
                <td>{{ index + 1 }}</td>
                <!-- category -->
                <td class="">
                  {{
                    item.category.parent
                      ? item.category.parent.name
                      : item.category.name
                  }}
                </td>
                <!-- subcategory -->
                <td class="">
                  {{ item.category.parent ? item.category.name : "" }}
                </td>
                <!-- reference -->
                <td class="nowrap">
                  {{ item.reference && item.reference.name }}
                </td>
                <!-- code -->
                <td class="nowrap">{{ item.code }}</td>
                <!-- description -->
                <td>{{ item.description }}</td>
                <!-- name -->
                <td>{{ item.unit.name }}</td>
                <!-- unit cost -->
                <td class="has-text-right nowrap">
                  {{ item.prestationSST.cost | priceEUR }}
                </td>
                <!-- os_quantity -->
                <td class="has-text-right cell-warning-light">
                  {{
                    item.prestationOS
                      ? $options.filters.number(item.prestationOS.quantity, 1)
                      : "N/A"
                  }}
                </td>
                <!-- os_amount -->
                <td class="has-text-right nowrap cell-warning-light">
                  {{
                    item.prestationOS
                      ? $options.filters.priceEUR(
                          item.prestationOS.quantity * item.prestationSST.cost
                        )
                      : "N/A"
                  }}
                </td>
                <!-- prestation_cumulative_quantity -->
                <td class="has-text-right cell-success-light">
                  {{ item.cumulativeQuantity | number(1) }}
                </td>
                <!-- prestation_cumulative_amount -->
                <td class="has-text-right nowrap cell-success-light">
                  {{
                    (item.cumulativeQuantity * item.prestationSST.cost)
                      | priceEUR
                  }}
                </td>
                <!-- prestation_cumulative_previous_quantity -->
                <td class="has-text-right cell-primary-light">
                  {{ item.cumulativePreviousQuantity | number(1) }}
                </td>
                <!-- prestation_cumulative_previous_amount -->
                <td class="has-text-right nowrap cell-primary-light">
                  {{
                    (item.cumulativePreviousQuantity * item.prestationSST.cost)
                      | priceEUR
                  }}
                </td>
                <!-- prestation_quantity -->
                <td class="has-text-right cell-info-light">
                  {{ item.prestationAttachement.quantity | number(1) }}
                </td>
                <!-- prestation_amount -->
                <td class="has-text-right nowrap cell-info-light">
                  {{
                    (item.prestationAttachement.quantity *
                      item.prestationSST.cost)
                      | priceEUR
                  }}
                </td>
              </tr>
              <tr v-if="editMode && filter(item)">
                <!-- index -->
                <td>{{ index + 1 }}</td>
                <!-- category -->
                <td class="">
                  {{
                    item.category.parent
                      ? item.category.parent.name
                      : item.category.name
                  }}
                </td>
                <!-- subcategory -->
                <td class="">
                  {{ item.category.parent ? item.category.name : "" }}
                </td>
                <!-- reference -->
                <td class="filterable">
                  {{ item.reference && item.reference.name }}
                </td>
                <!-- code -->
                <td class="filterable">{{ item.code }}</td>
                <!-- description -->
                <td class="filterable">{{ item.description }}</td>
                <!-- name -->
                <td class="filterable">{{ item.unit.name }}</td>
                <!-- unit cost -->
                <td class="filterable has-text-right nowrap">
                  {{ item.prestationSST.cost | priceEUR }}
                </td>
                <!-- os_quantity -->
                <td class="filterable has-text-right">
                  {{
                    item.prestationOS
                      ? $options.filters.number(item.prestationOS.quantity, 1)
                      : "N/A"
                  }}
                </td>
                <!-- os_amount -->
                <td class="has-text-right">
                  {{
                    item.prestationOS
                      ? $options.filters.priceEUR(
                          item.prestationOS.quantity * item.prestationSST.cost
                        )
                      : "N/A"
                  }}
                </td>
                <!-- prestation_cumulative_quantity -->
                <td class="filterable has-text-right">
                  {{ item.cumulativeQuantity | number(1) }}
                </td>
                <!-- prestation_cumulative_amount -->
                <td class="filterable has-text-right">
                  {{
                    (item.cumulativeQuantity * item.prestationSST.cost)
                      | priceEUR
                  }}
                </td>
                <!-- prestation_cumulative_previous_quantity -->
                <td class="filterable has-text-right">
                  {{ item.cumulativePreviousQuantity | number(1) }}
                </td>
                <!-- prestation_cumulative_previous_amount -->
                <td class="has-text-right">
                  {{
                    (item.cumulativePreviousQuantity * item.prestationSST.cost)
                      | priceEUR
                  }}
                </td>
                <!-- prestation_quantity -->
                <td class="filterable">
                  <input
                    type="number"
                    step="0.5"
                    class="input"
                    cy-data="prestation-quantity"
                    v-model="item.prestationAttachement.quantity"
                  />
                </td>
                <!-- prestation_amount -->
                <td class="has-text-right nowrap">
                  {{
                    (item.prestationAttachement.quantity *
                      item.prestationSST.cost)
                      | priceEUR
                  }}
                </td>
              </tr>
            </template>

            <template slot="lastDataRow" slot-scope="{ items }">
              <tr v-if="editMode">
                <td colspan="16" class="has-text-centered">
                  <button
                    class="button has-background-success"
                    @click="openPrestationSelectionModal"
                    cy-data="add-prestation-button"
                  >
                    <i :style="{ color: 'white' }" class="fa fa-plus"></i>
                  </button>
                </td>

                <td slot="action"></td>
              </tr>

              <tr v-if="!editMode && osContainsPrestations && !!items.length">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="has-text-right">
                  <span class="has-text-weight-bold">{{
                    osQuantity | number(1)
                  }}</span>
                </td>
                <td class="has-text-right nowrap">
                  <span class="has-text-weight-bold">{{
                    osTotalCost | priceEUR
                  }}</span>
                </td>
                <td class="has-text-right">
                  <span class="has-text-weight-bold">{{
                    cumulativeQuantity | number(1)
                  }}</span>
                </td>
                <td class="has-text-right nowrap">
                  <span class="has-text-weight-bold">{{
                    cumulativeCost | priceEUR
                  }}</span>
                </td>
                <td class="has-text-right">
                  <span class="has-text-weight-bold">{{
                    cumulativePreviousQuantity | number(1)
                  }}</span>
                </td>
                <td class="has-text-right nowrap">
                  <span class="has-text-weight-bold">{{
                    cumulativePreviousCost | priceEUR
                  }}</span>
                </td>
                <td class="has-text-right">
                  <span class="has-text-weight-bold">{{
                    totalPrestationQuantity | number(1)
                  }}</span>
                </td>
                <td class="has-text-right nowrap">
                  <span class="has-text-weight-bold">{{
                    totalPrestationCost | priceEUR
                  }}</span>
                </td>
              </tr>
            </template>
          </flexi-table>

          <prestation-selector-modal
            ref="selectPrestationsModal"
            :fetch="
              $api.fetchPrestationsSst.bind(this, this.attachement.bpu.id)
            "
            @input="addNewPrestation"
          />
        </div>
        <div class="panel-block is-block no-border" v-if="currentTab === 2">
          <document-manager
            :objectName="$entitiesName.Attachement"
            :objectId="id"
            :deletable="canDeleteDocuments"
          ></document-manager>
        </div>
      </div>
    </div>
    <input
      ref="attFileInput"
      type="file"
      :style="{ display: 'none' }"
      name="prestationAttFile"
      accept="text/csv"
      @change="importFile"
    />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { Workflows as WORKFLOWS } from "../../constants/Workflow";

import PrestationSelectorModal from "./PrestationSelectorModal";

const initialSorting =
  "prestation.category.parent.name,prestation.category.name,prestation.description,ASC";

export default {
  name: "attachement",
  props: ["id"],
  components: {
    PrestationSelectorModal,
  },
  data() {
    return {
      currentTab: 0,
      attachement: {},
      WorkflowCodes: WORKFLOWS,
      prestations: [],
      prestationTableColumns: [
        {
          title: this.$t("common.category"),
          name: "category",
          accessor: "category.parent.name",
          filter: "",
          sort: "prestation.category.parent.name",
          class: "sortable",
        },
        {
          title: this.$t("common.subcategory"),
          name: "subcategory",
          accessor: "category.name",
          filter: "",
          class: "sortable",
          sort: "prestation.category.name",
        },
        {
          title: this.$t("common.reference"),
          name: "reference",
          accessor: "reference.name",
          filter: "",
          sort: "prestation.reference.name",
          class: "sortable",
        },
        {
          title: "Code",
          name: "code",
          accessor: "code",
          filter: "",
          sort: "prestation.code",
          class: "sortable",
        },
        {
          title: this.$t("common.designation"),
          name: "description",
          accessor: "description",
          filter: "",
          sort: "prestation.description",
          class: "sortable",
        },
        {
          title: this.$t("common.unit"),
          name: "unit",
          accessor: "unit.name",
          filter: "",
          sort: "prestation.unit.name",
          class: "sortable",
        },
        {
          title: this.$t("prestations.columns.unitPrice"),
          name: "cost",
          accessor: "prestationSST.cost",
          filter: "",
          sort: "cost",
          class: "has-text-centered sortable",
        },
        {
          title: this.$t("attachments.prestationColumns.orderQuantity"),
          name: "os_quantity",
          accessor: "prestationOS.quantity",
          filter: "",
          class: "has-text-centered",
        },
        {
          title: this.$t("attachments.prestationColumns.orderAmount"),
          name: "os_amount",
          accessor: "",
          filter: "",
          class: "has-text-centered",
        },
        {
          title: this.$t("attachments.prestationColumns.cumulativeQuantity"),
          name: "prestation_cumulative_quantity",
          accessor: "cumulativeQuantity",
          filter: "",
          class: "has-text-centered",
          help: this.$t("attachments.prestationColumns.help"),
        },
        {
          title: this.$t("attachments.prestationColumns.cumulativeAmount"),
          name: "prestation_cumulative_amount",
          accessor: "",
          filter: "",
          class: "has-text-centered",
        },
        {
          title: this.$t(
            "attachments.prestationColumns.cumulativePreviousQuantity"
          ),
          name: "prestation_cumulative_previous_quantity",
          accessor: "cumulativePreviousQuantity",
          filter: "",
          class: "has-text-centered",
          help: this.$t("attachments.prestationColumns.helpTwo"),
        },
        {
          title: this.$t(
            "attachments.prestationColumns.cumulativePreviousAmount"
          ),
          name: "prestation_cumulative_previous_amount",
          accessor: "",
          filter: "",
          class: "has-text-centered",
        },
        {
          title: this.$t("attachments.prestationColumns.currentQuantity"),
          name: "prestation_quantity",
          accessor: "prestationAttachement.quantity",
          filter: "",
          class: "has-text-centered",
        },
        {
          title: this.$t("attachments.prestationColumns.currentAmount"),
          name: "prestation_amount",
          accessor: "",
          filter: "",
          class: "has-text-centered",
        },
      ],
      API: axios.defaults.baseURL,
      authToken: this.$store.getters["auth/getToken"],
      workflowComment: "",
      isLoading: false,
      loadingPrestations: false,
      sendToOracleLoader: false,
      ite: null,
      sorting: initialSorting,
    };
  },
  mounted() {
    this.$store.dispatch("states/setEdition", false);
    this.fetchData();
  },
  watch: {
    prestations(val) {
      if (this.$refs.prestationsTable) {
        this.$refs.prestationsTable.fetch(val);
      }
    },
    sorting() {
      this.fetchPrestations();
    },
  },
  computed: {
    ...mapGetters({
      editMode: "states/isEdition",
      getRights: "auth/getRights",
      user: "auth/getUser",
      ActiveProfile: "auth/getActiveProfile",
    }),
    canDeleteDocuments() {
      return !!(
        this.attachement.statusWF &&
        [
          this.WorkflowCodes.ATT.NODE.DRAFT,
          this.WorkflowCodes.ATT.NODE.VALIDATING,
        ].indexOf(this.attachement.statusWF.node.nodeCode) > -1
      );
    },
    osContainsPrestations() {
      return this.prestations.length > 0;
    },
    osQuantity() {
      let qty = 0;
      this.prestations.forEach((p) => {
        if (p.prestationOS) qty += p.prestationOS.quantity;
      });
      return qty;
    },
    osTotalCost() {
      let cost = 0;
      this.prestations.forEach((p) => {
        if (p.prestationOS)
          cost += p.prestationOS.quantity * p.prestationSST.cost;
      });
      return cost;
    },
    cumulativeQuantity() {
      let qty = 0;
      this.prestations.forEach((p) => {
        qty += p.cumulativeQuantity;
      });
      return qty;
    },
    cumulativeCost() {
      let cost = 0;
      this.prestations.forEach((p) => {
        cost += p.cumulativeQuantity * p.prestationSST.cost;
      });
      return cost;
    },
    cumulativePreviousQuantity() {
      let qty = 0;
      this.prestations.forEach((p) => {
        qty += p.cumulativePreviousQuantity;
      });
      return qty;
    },
    cumulativePreviousCost() {
      let cost = 0;
      this.prestations.forEach((p) => {
        cost += p.cumulativePreviousQuantity * p.prestationSST.cost;
      });
      return cost;
    },
    totalPrestationQuantity() {
      return this.attachement.quantity;
    },
    totalPrestationCost() {
      return this.attachement.amount;
    },
    validated() {
      return (
        this.attachement.statusWF.node.nodeCode === "att_validated" ||
        this.attachement.statusWF.node.nodeCode ===
          "att_prepared_for_payment" ||
        this.attachement.statusWF.node.nodeCode === "att_paid"
      );
    },
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      return Promise.all([
        this.fetchAttachment(),
        this.fetchPrestations(),
      ]).finally(() => {
        this.isLoading = false;
      });
    },
    fetchAttachment() {
      return axios.get(`/attachement/${this.id}`).then((response) => {
        this.attachement = response.data;
      });
    },
    fetchPrestations(callback) {
      this.loadingPrestations = true;
      return axios
        .get(`/attachement/${this.id}/prestations?sort=${this.sorting}`)
        .then((res) => {
          const withAtt = res.data.map((p) => {
            const prestation = {
              ...p,
            };
            if (!p.prestationAttachement) {
              prestation.prestationAttachement = {
                quantity: 0,
                id: null,
              };
            }
            return prestation;
          });
          if (typeof callback === "function") {
            callback(withAtt);
          }
          this.updatePrestationsModel(withAtt);
          this.loadingPrestations = false;
          if (this.$refs.prestationsTable) {
            this.$refs.prestationsTable.setLoading(false);
          }
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(e);
        });
    },
    returnPrestations(callback) {
      callback(this.prestations);
      if (this.loadingPrestations && this.currentTab === 1) {
        this.$refs.prestationsTable.setLoading(true);
      }
    },
    updatePrestationsModel(val) {
      this.prestations = val;
    },
    save() {
      return this.$validator.validateAll().then((result) => {
        if (result) {
          // add prestations to save
          const attachement = {
            ...this.attachement,
            prestationAttachements: this.prestations.filter(
              (p) =>
                p.prestationAttachement &&
                ((p.prestationAttachement.quantity !== "0" &&
                  p.prestationAttachement.quantity !== "") ||
                  p.prestationAttachement.id !== null)
            ),
          };
          return axios
            .put(`/attachement/${this.id}`, attachement)
            .then((response) => {
              if (response.data.success) {
                const methodsToExecute = [this.fetchAttachment()];
                if (this.currentTab === 1) {
                  methodsToExecute.push(this.$refs.prestationsTable.fetch());
                }
                return Promise.all(methodsToExecute);
              }
              return Promise.resolve();
            })
            .then(() => {
              this.fetchPrestations();
            })
            .finally(() => {
              this.$store.dispatch("states/setEdition", false);
            });
        }
        return Promise.resolve();
      });
    },
    edit() {
      this.$store.dispatch("states/setEdition", true);
    },
    cancel() {
      this.fetchData().finally(() => {
        this.$store.dispatch("states/setEdition", false);
      });
    },
    remove() {
      return this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cet attachement ?",
        () =>
          axios.delete(`/attachement/${this.id}/delete`).then((response) => {
            if (response.data.success) {
              this.$router.replace("/sst/attachements");
            }
          })
      );
    },
    filter(item) {
      return this.prestationTableColumns.every((p) => {
        const value = this.getProperty(item, p.accessor);

        // Escape all the characters provided by the user that could be interpreted in the Regex
        const filter = p.filter.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

        // Prepare the regex
        const rx = new RegExp(`.*${filter}.*`, "gi");

        // Evaluate
        return rx.test(value);
      });
    },
    getProperty(obj, accessor) {
      const props = accessor.split(".");
      let result = obj;
      for (let i = 0; i < props.length; i += 1) {
        const p = props[i];
        if (!result[p]) {
          return "";
        }
        result = result[p];
      }
      return result;
    },
    importClick() {
      // eslint-disable-next-line
      this.$refs.attFileInput.click();
    },
    importFile(e) {
      if (e.target.files[0]) {
        const form = new FormData();
        form.set("file", e.target.files[0]);
        axios
          .post(`/attachement/${this.id}/import`, form, {
            headers: { "Content-Type": "charset=UTF-8" },
          })
          .then(() => {
            this.$refs.prestationsTable.fetch();
            this.fetchPrestations();
            // eslint-disable-next-line
            alert("Import effectué avec succès");
          })
          .catch((err) => {
            // eslint-disable-next-line
            console.error(err);
          });
      }
    },
    addNewPrestation(item) {
      // Skip if the prestation is already there.
      if (
        this.prestations.some((p) => p.prestation.id === item.prestation.id)
      ) {
        return;
      }
      const prestation = item;

      prestation.cumulativeQuantity = "";
      prestation.cumulativePreviousQuantity = "";

      prestation.prestationSST = {
        id: item.id,
        cost: item.cost,
      };
      prestation.prestationOS = {
        id: null,
        quantity: 0,
      };
      this.$set(prestation, "prestationAttachement", {
        id: null,
        quantity: 0,
      });
      this.prestations.push(prestation);
    },
    openPrestationSelectionModal() {
      this.$refs.selectPrestationsModal.open();
    },
    updateSort(newValue) {
      if (newValue === "initialValue") {
        this.sorting = initialSorting;
        return;
      }

      this.sorting = newValue;
    },
    sendToOracle() {
      this.sendToOracleLoader = true;
      return axios.post(`oracle/attachement/${this.id}`).finally(() => {
        this.sendToOracleLoader = false;
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.panel {
  display: inline-block;
  min-width: 100%;
}

.panel-heading {
  padding: 0em 0.75em;
  .tabs {
    ul {
      border-bottom: none;
    }
    li {
      width: 25%;
      a {
        color: #fff;
        border-bottom: none;
        padding: 1em 0em;
      }
      &.is-active a {
        color: #000;
        background-color: #fff;
      }
    }
  }
}

.is-fullwidth {
  width: 100%;
}

tr {
  td.filterable {
    width: 13%;
  }
}

.inline-form {
  display: inline;
}

.selector {
  max-width: 700px;
}

.table-data {
  .cell-info-light {
    background-color: hsl(204, 45%, 85%);
    border: 1px solid white;
  }

  .cell-warning-light {
    background-color: hsl(48, 100%, 86%);
    border: 1px solid white;
  }

  .cell-success-light {
    background-color: hsl(141, 71%, 77%);
    border: 1px solid white;
  }

  .cell-primary-light {
    background-color: hsl(171, 67%, 75%);
    border: 1px solid white;
  }
}
</style>
