<template>
  <NotificationContainer :readedAt="readedAt" error>
    <div class="notification-detail">
      <p>
        <b
          >Impossible d'envoyer la commande
          <router-link :to="'/sst/order/' + context.orderId">{{
            context.orderNumber
          }}</router-link>
          dans Oracle</b
        >
      </p>
      <p class="py-2">
        {{ context.errorMessage }}
      </p>
      <!--
      <p class="text-muted">
        Voir la commande<br />
        <router-link
          :to="'/sst/order/' + context.orderId"
          class="button is-small is-primary"
          >{{ context.orderNumber }}</router-link
        >
      </p>
      -->
      <span class="text-muted">
        <i>
          <small>{{ createdAt | fromNow }}</small>
        </i>
      </span>
    </div>
  </NotificationContainer>
</template>

  <script>
export default {
  name: "OracleSendOrderFailure",
  props: {
    readedAt: {},
    context: {},
    creator: {},
    template: {},
    createdAt: {},
    user: {},
  },
};
</script>

  <style lang="scss" scoped>
.notification-detail {
  p {
    margin-bottom: 5px;
    line-height: 1.2;
  }
}

.py-2 {
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
