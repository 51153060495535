<template>
  <NotificationContainer :readedAt="readedAt" success>
    <div class="notification-detail">
      <p>
        <b
          >Attachement
          <router-link :to="'/sst/attachement/' + context.attachementId">{{
            context.attachementReference
          }}</router-link>
          envoyée dans Oracle</b
        >
      </p>
      <span class="text-muted">
        <i>
          <small>{{ createdAt | fromNow }}</small>
        </i>
      </span>
    </div>
  </NotificationContainer>
</template>

<script>
export default {
  name: "OracleSendReceiptSuccess",
  props: {
    readedAt: {},
    context: {},
    creator: {},
    template: {},
    createdAt: {},
    user: {},
  },
};
</script>

<style lang="scss" scoped>
.notification-detail {
  p {
    margin-bottom: 5px;
    line-height: 1.2;
  }
}

.py-2 {
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
